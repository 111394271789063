import React from 'react';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { breakpoints } from '@naf/theme';
import { ImageCard } from '@naf/cards';
import styled from 'styled-components';

import { ImageLinksType } from '../../../../../../types/imageLinksType';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { StyledLink } from '../../../styled-link/StyledLink';
import { LinkWithImageType } from '../../../../../../types/CategoryListWithScrollType';
import ResponsiveImage from '../../../image/ResponsiveImage';

type ImageLinksBlockProps = {
  block: ImageLinksType;
};

export const InternalLinkCard = ({ item: { link, image } }: { item: LinkWithImageType }) => {
  const linkImage = image || link.internalReference?.image;
  const imgId = linkImage?.public_id || linkImage?.publicId;

  const href = useDocumentUrlWithNode(link.internalReference);

  if (href) {
    return (
      <StyledLink to={href}>
        <ImageCard
          title={link.title}
          advancedImage={<ResponsiveImage imageId={imgId} altText={image?.altText || image?.alt} />}
          isInternalLink
        />
      </StyledLink>
    );
  }
  if (link.externalTarget?.href) {
    return (
      <ImageCard
        title={link.title}
        href={link.externalTarget.href}
        target={link?.externalTarget?.blank ? '_blank' : '_self'}
        advancedImage={<ResponsiveImage imageId={imgId} altText={image?.altText || image?.alt} />}
      />
    );
  }
  return null;
};

export const ImageLinks = ({ imageLinks }: { imageLinks: LinkWithImageType[] }) => {
  const GridColumn = imageLinks?.length > 1 ? GridCol : StyledGridCol;
  if (imageLinks?.length === 0) {
    return null;
  }
  return (
    <Grid className="ImageLinksBlock">
      <GridRow>
        {imageLinks.map((item) => (
          <GridColumn s="12" m="6" l="6" xl="6" key={item._key || item.key}>
            <InternalLinkCard item={item} />
          </GridColumn>
        ))}
      </GridRow>
    </Grid>
  );
};

export const ImageLinksBlock = ({ block }: ImageLinksBlockProps) => <ImageLinks imageLinks={block.imageLinks} />;

export const StyledGridCol = styled(GridCol)`
  grid-column-start: 4;
  @media (max-width: ${breakpoints.s}) {
    grid-column-start: 1;
  }
`;
